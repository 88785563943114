import axios from "axios";
import { StationBaseUrl, baseApiVersion,StationBaseApiPreFix, headers } from "../../config/env";

export function updateStationDeviceManagerScheduleChange(stationDeviceId, params) {
    return axios.patch(`${StationBaseUrl}${baseApiVersion}${StationBaseApiPreFix}/device/manager/${stationDeviceId}/schedule`, params, {headers});
}

export function updateStationDeviceManagerScheduleChangeList(params) {
    return axios.patch(`${StationBaseUrl}${baseApiVersion}${StationBaseApiPreFix}/device/manager/schedule/list`, params, {headers});
}


export function updateStationDeviceManagerInfo(stationDeviceId, params) {
    return axios.patch(`${StationBaseUrl}${baseApiVersion}${StationBaseApiPreFix}/device/manager/${stationDeviceId}`, params, {headers});
}
