<template>
  <div>
    <div class="title flexB" style="margin-top: 0;">
      <h1>{{ $t("station-detail-title") }}</h1>
    </div>
    <div class="contents register">
      <div class="mainBox">
        <div class="flexL">
          <p class="bold">{{ $t("station-data-serial-num") }}<span>*</span></p>
          <p> {{ stationInfo == null ? '-' : stationInfo.deviceSerial  }}</p>
        </div>

        <div class="flexL" style="margin-bottom: 4rem;">
          <p class="bold">{{ $t("station-data-drone-serial-num") }}<span>*</span></p>
          <p> {{ stationInfo == null ? '-' : stationInfo.childSerial  }}</p>
        </div>

        <div class="flexL">
          <p class="bold">{{ $t("station-data-stationName") }}<span>*</span></p>
          <input type="text" style="width:50rem;" v-model = "stationName"/>
          <input type="number" style="width:10rem; margin-left:10px;" v-model= "stationTag"/>
          <span style="margin-left: 5px;">번</span>
        </div>

        <div class="flexL">
          <p class="bold">{{ $t("station-data-live-quality") }}<span>*</span></p>
          <select v-model="stationLiveQuality">
            <option v-for="item in liveQuality" :value="item.value" :key="'dock_live_'+item.value">{{item.name}} </option>
          </select>
        </div>

        <div class="flexL">
          <p class="bold">{{ $t("station-data-droneModel") }}<span>*</span></p>
          <p>{{stationInfo == null || stationInfo.childStationDevice == null ? '-' : stationInfo.childStationDevice.modelName}}</p>
        </div>

        <div class="flexL">
          <p class="bold">{{ $t("station-data-drone-live-quality") }}<span>*</span></p>
          <select v-model="stationDroneLiveQuality">
            <option v-for="item in liveQuality" :value="item.value" :key="'drone_live_'+item.value">{{item.name}} </option>
          </select>
        </div>

        <div class="flexL">
          <p class="bold">{{ $t("station-data-droneName") }}<span>*</span></p>
          <input type="text" style="width:50rem;" v-model = "stationDroneName"/>
        </div>

        <div class="flexL">
          <p class="bold">{{ $t("station-data-managerSite") }}<span>*</span></p>
          <div style="display: flex;flex-direction: column;">
            <div>
              <input type="text" class="modalInput" style="width:50rem;" @click="openStationSite" :value="showEventTitle" readonly/>
              <span style="margin-left: 10px; color: #001300;">{{ showEventTitle !== stationEventTitle ? stationEventTitle : null }}</span>
            </div>
            <span style="color:#ff3838;">현장 선택 시 사용자 선택이 가능해집니다.</span>
          </div>
        </div>

        <div class="flexL">
          <p class="bold">{{ $t("station-data-station-manager-id") }}<span>*</span></p>
          <input type="text" class="modalInput" style="width:50rem;" @click="openStationUser('Station')" :value="showStationUserName" readonly/>
          <span style="margin-left: 10px; color: #001300;">{{ showStationUserName !== stationUserName ? ( stationUserName == null ? '' : ' #' + stationUserName ) : null }}</span>

        </div>

        <div class="flexL">
          <p class="bold">{{ $t("station-data-dept") }}<span>*</span></p>
          <p v-if="showStationUserDepartmentName != null">{{ showStationUserDepartmentName }}</p> <!-- 사용자 선택 시 소속 표출 -->
          <p v-else>-</p>
          <p style="margin-left: 10px">{{ showStationUserDepartmentName !== stationUserDepartmentName ? (stationUserDepartmentName == null ? '' : '( #' + stationUserDepartmentName + ')' ) : null }} </p>
        </div>

        <div class="flexL">
          <p class="bold">{{ $t("station-data-drone-manager-id") }}<span>*</span></p>
          <input type="text" class="modalInput" style="width:50rem;" @click="openStationUser('Drone')" :value="showDroneUserName" readonly/>
          <span style="margin-left: 10px; color: #001300;">{{ showDroneUserName !== stationDroneUserName ? ( stationDroneUserName == null ? '' : ' #' + stationDroneUserName ) : null  }}</span>
        </div>

        <div class="flexL">
          <p class="bold">{{ $t("station-data-dept") }}<span>*</span></p>
          <p v-if="showDroneUserDepartmentName != null">{{ showDroneUserDepartmentName }}</p> <!-- 사용자 선택 시 소속 표출 -->
          <p v-else>-</p>
          <p style="margin-left: 10px">{{ showDroneUserDepartmentName !== stationDroneUserDepartmentName ? (stationDroneUserDepartmentName == null ? '' : '( #' + stationDroneUserDepartmentName + ')' ) : null }} </p>
        </div>

        <div class="flexL">
          <p class="bold">{{ $t("station-data-linkDate") }}<span>*</span></p>
          <p> {{  stationInfo == null ? '-' : moment(Date(stationInfo.boundTime)).format("YYYY-MM-DD hh:mm:ss") }} </p>
        </div>

        <div class="buttonWrap">
          <div>
            <button class="point large" @click="setStationManager">{{ $t("btn-confirm") }}</button>
            <button class="large" @click="$router.go(-1)">{{ $t("btn-cancel") }}</button>
          </div>
        </div>
      </div>
    </div>

    <!-- modal -->
    <div v-if="showStationSiteModal" class="modal-mask">
      <div class="modal-container">
        <div class="modal-title">
            <h3>관리현장 선택</h3>
            <button @click="closeStationSiteModal"><img src="@/assets/images/icon_svg/close-icon.png" alt="icon" style="width: 12px;" /></button>
        </div>
        <div class="modal-input">
          <div class="modal-search">
            <input type="text" style="width: 430px;" v-model="eventKeyword" @keydown.enter="enterSearchType('Event')" >
            <div class="buttonWrap">
              <button @click="eventSearch">검색</button>
              <button @click="eventInit" >초기화</button>
            </div>
          </div>
        </div>
        <div class="modal-content">
          <div class="modal-list-title">
            <h4>{{ $t("top-select-site-view-list") }}</h4>
            <h5><span>{{ eventList.length }}</span>곳</h5>
          </div>
          <ul class="modal-list">
            <li v-for="(site, index) in eventList" :key="index" @click="selectSite(site)">
              <h3>{{ site.title }}</h3>
              <h4>
                <span>{{ site.address }}</span>
                <span>│</span>
                <span>{{ site.managerDepartmentTitle }}</span>
              </h4>
            </li>
          </ul>
        </div>
        <!-- <div class="modal-btn">
          <button class="btn-save">확인</button>
          <button @click="closeStationSiteModal">닫기</button>
        </div> -->
      </div>
    </div>

    <!-- user modal -->
    <div v-if="showStationUserModal" class="modal-mask">
      <div class="modal-container">
        <div class="modal-title">
            <h3>관리유저 선택</h3>
            <button @click="closeStationUserModal"><img src="@/assets/images/icon_svg/close-icon.png" alt="icon" style="width: 12px;" /></button>
        </div>
        <div class="modal-input">
          <div class="modal-search">
            <input type="text" style="width: 430px;" v-model="userKeyword" @keydown.enter="enterSearchType('User')">
            <div class="buttonWrap">
              <button @click="userSearch">검색</button>
              <button @click="userInit">초기화</button>
            </div>
          </div>
        </div>
        <div class="modal-content">
          <div class="modal-list-title">
            <h4>{{ $t("top-select-user-view-list") }}</h4>
            <h5><span>{{ userList.length }}</span>명</h5>
          </div>
          <ul class="modal-list">
            <li v-for="(user, index) in userList" :key="index" @click="selectUser(user)">
              <h3>{{ user.name }}</h3>
              <h4>
                <span>{{ user.departmentInfo.name }}</span>
                <span>│</span>
                <span>{{ user.loginId }}</span>
              </h4>
            </li>
          </ul>
        </div>
        <!-- <div class="modal-btn">
          <button class="btn-save">확인</button>
          <button @click="closeStationSiteModal">닫기</button>
        </div> -->
      </div>
    </div>

  </div>
</template>
<style>
.title {
  display: block;
}
.modalInput:hover{cursor: pointer;background: #eee;}

.modal-mask {position: fixed;z-index: 9998;top: 0;left: 0;width: 100%; height: 100%;background-color: rgba(0, 0, 0, 0.5);display: flex;align-items: center;justify-content: center;}

.modal-wrapper {width: auto;}

.modal-container {background-color: white;padding: 20px;border-radius: 5px;}
.modal-title {display: flex;align-items: center;justify-content: space-between;border-bottom: 1px solid #ddd;margin-bottom: 15px;padding-bottom: 10px;}
.modal-title>h3 {font-size: 1.6rem;}
.modal-title>button {border: 0;}

.modal-search {display: flex;align-items: center;}
.modal-search>span {color: #969696;background: #eee;padding: 8px 15px;border-radius: 5px;margin-right: 10px;}
.modal-search>select {background: #ffffff url(/img/arrow_down.2fb6f130.svg) no-repeat calc(100% - 5px) center / 20px auto;width: 110px;border: 1px solid #ddd;height: 32px;padding: 5px 25px 5px 12px;border-radius: 5px;font-size: 1.2rem;color: black;}
.modal-search>select>option {background: #ffffff;color: #969696;}
.modal-search>input {margin: 0;background: #ffffff;border: 0;color: #000;border-radius: 5px;border: 1px solid #ddd;}
.buttonWrap {margin: 0; margin-left: 10px;}
.buttonWrap>button {padding: 2px 27px;border: 0;font-weight: 300;color: #fff;border-radius: 5px;}
.buttonWrap>button:nth-child(1) {background: #0080FF;margin-right: 8px;}
.buttonWrap>button:nth-child(2) {background: #fff;color: #969696;border: 1px solid #ddd;}

.modal-content {padding-bottom: 15px;border-bottom: 1px solid #ddd;}
.modal-list-title{    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;}
.modal-list-title > h4{font-size: 1.4rem;}
.modal-list-title > h5{font-size: 1.2rem;}
.modal-list-title > h5 > span{color: #0080ff;}

.modal-list{margin-top: 10px;height: 300px;max-height: 300px;overflow-y: auto;border-top: 1px solid #ddd;}
.modal-list > li{border-bottom: 1px solid #ddd;padding: 15px 10px;}
.modal-list > li:hover{background: #eee; cursor: pointer;}
.modal-list > li > h3{font-size: 1.6rem;margin-bottom: 5px;}
.modal-list > li > h4{color: #969696;font-weight: 400;}
.modal-list > li > h4 > span{font-size: 1.2rem;}

.modal-btn {margin-top: 15px;margin-bottom: 10px;display: flex;justify-content: center;}
.modal-btn>button.btn-save {background: #0080ff;color: #fff;border: 1px solid #0080ff;}
.modal-btn>button {margin: 0 5px;width: 120px;height: 35px;border: 1px solid #ddd;border-radius: 5px;color: #969696;font-size: 1.4rem;}
</style>
<script>
import moment from "moment";
import {fetchStationDeviceInfo} from "@/api/station/stationDevice";
import { fetchEventList } from "@/api/event";
import { fetchUserList } from "@/api/user";
import {updateStationDeviceManagerInfo} from "@/api/station/stationDeviceManager";

export default {
  components: {
  },
  name: "Register",
  data() {
    return {
      moment: moment,
      showStationSiteModal: false,
      showStationUserModal: false,

      stationDeviceId : null,
      stationInfo : null,

      liveQuality : [
          {value : 'SD', name : "SD"},
        // {value : 'HD_L', name : "저해상도 HD"},
        {value : 'HD_H', name : "HD"},
        // {value : 'FHD', name : "Full HD"}
      ],

      //region 스테이션 데이터 등록
      stationName : null,
      stationDroneName : null,
      stationTag : null,
      stationEventTitle : null,
      stationUserName : null,
      stationUserDepartmentName : null,
      stationDroneUserName : null,
      stationDroneUserDepartmentName : null,
      stationLiveQuality : null,
      stationDroneLiveQuality : null,
      //endregion

      eventKeyword : null,
      selectedSite: null,
      eventList: [],

      userKeyword : null,
      selectedStationUser: null,
      selectedDroneUser: null,
      userList: [],
      openUserInfoType : null,


      showStationUserName : null,
      showStationUserDepartmentName : null,
      showDroneUserName: null,
      showDroneUserDepartmentName : null,
    };
  },
  computed: {
    showEventTitle(){

      let result = this.selectedSite != null ? this.selectedSite.title : null
      if(result == null) {
        result = this.stationEventTitle != null ? this.stationEventTitle : null
      }
      return result
    },
  },
  created() {
    this.stationDeviceId = this.$route.params.stationDeviceId;
  },
  mounted() {
    this.getStationInfo()
  },
  watch : {

  },

  methods: {

    enterSearchType(type) {
      if(type === 'Event') {
        this.eventSearch()
      }
      if(type === 'User') {
        this.userSearch()
      }
    },

    //region station model
    openStationSite() {
        this.showStationSiteModal = true;
        this.eventList = [];
        this.getEventList();
    },
    closeStationSiteModal() {
        this.showStationSiteModal = false;
    },
    eventSearch(){
      if(this.eventKeyword == "") {
        this.eventKeyword = null
      }
      this.getEventList(this.eventKeyword)
    },
    eventInit(){
      this.eventKeyword = null
    },
    getEventList(keyword) {

      let params = {
        pageNumber: 0,
        statuses: "Open",
        keyword : keyword == undefined ? null : keyword,
      };

      fetchEventList(params).then((res) => {
        this.eventList = res.data.data.content;
      });
    },
    selectSite(site) {
      // 선택한 현장의 이름을 input 필드에 반영
      if(this.selectedSite == null || this.selectedSite.eventId !== site.eventId) {
        this.selectedSite = site;
      }

      if(this.selectedSite != null && this.selectedSite.eventId !== this.stationInfo.eventId) {
        if(this.selectedStationUser != null) {
          this.selectedStationUser = null
        }

        if(this.selectedDroneUser != null) {
          this.selectedDroneUser = null
        }

        this.showStationUserName = null
        this.showStationUserDepartmentName = null;

        this.showDroneUserName = null
        this.showDroneUserDepartmentName = null;

        if(this.selectedSite.managerDepartmentId === this.stationInfo.managerData.eventManagerDepartmentId) {
          this.showStationUserName = this.stationUserName;
          this.showStationUserDepartmentName = this.stationUserDepartmentName;
        }

        if(this.stationInfo.childStationDevice != null &&  this.selectedSite.managerDepartmentId === this.stationInfo.childStationDevice.managerData.eventManagerDepartmentId) {
          this.showDroneUserName = this.stationDroneUserName;
          this.showDroneUserDepartmentName = this.showStationUserDepartmentName;
        }
      }
      this.closeStationSiteModal();
    },
    //endregion

    //region user model
    openStationUser(openInfoType) {
      this.openUserInfoType = null;
        // selectedSite 값이 없으면 알림창을 띄움
      if (this.showEventTitle == null) {
        alert("먼저 현장을 선택하세요."); // 경고 메시지
      } else {
        // selectedSite가 있을 경우 사용자 모달을 열기
        this.showStationUserModal = true;
        this.userList = []
        this.openUserInfoType = openInfoType;
        this.userInit();
        this.userSearch();
      }
    },
    closeStationUserModal() {
        this.showStationUserModal = false;
    },
    userSearch(){
      if(this.userKeyword == "") {
        this.userKeyword = null
      }
      this.getUserList()
    },
    userInit(){
      this.userKeyword = null
    },
    getUserList() {
      let departmentId = this.selectedSite != null ? this.selectedSite.managerDepartmentId : null
      if(departmentId == null) {
        departmentId = this.stationInfo.managerData.eventManagerDepartmentId
      }

      if(departmentId == null) {
        return
      }

      let params = {
        pageNumber: 0, // pagination
        keyword : this.userKeyword,
        departmentId : departmentId,
      };

      fetchUserList(params).then((res) => {
        if (res.data.result == 0) {
          this.total = res.data.data.total;
          this.userList = res.data.data.content;
        }
      });
    },
    selectUser(user) {
      // 선택한 현장의 이름을 input 필드에 반영
      if(this.openUserInfoType === 'Station') {
        this.selectedStationUser = { ...user };
        this.showStationUserName = this.selectedStationUser.name;
        this.showStationUserDepartmentName = this.selectedStationUser.departmentInfo.name;
      }
      if(this.openUserInfoType === 'Drone') {
        this.selectedDroneUser = { ...user };
        this.showDroneUserName = this.selectedDroneUser.name;
        this.showDroneUserDepartmentName = this.selectedDroneUser.departmentInfo.name;
      }

      this.closeStationUserModal();
    },
    //endregion

    //region station
    getStationInfo() {
      fetchStationDeviceInfo(this.stationDeviceId).then((res) => {
        if (res.status == 200 && res.data.result == 0) {
          this.stationInfo = res.data.data;

          //스테이션 기본 정보 저장
          this.stationName = this.stationInfo.deviceName
          this.stationDroneName = this.stationInfo.childStationDevice == null ? null: this.stationInfo.childStationDevice.deviceName
          this.stationTag = this.stationInfo.managerData == null ? null : this.stationInfo.managerData.tag
          this.stationEventTitle = this.stationInfo.managerData == null ? null : this.stationInfo.managerData.eventTitle

          this.stationUserName = this.stationInfo.managerData == null ? null : this.stationInfo.managerData.userName
          this.stationUserDepartmentName = this.stationInfo.managerData == null ? null : this.stationInfo.managerData.userDepartmentName
          this.stationDroneUserName = this.stationInfo.childStationDevice == null || this.stationInfo.childStationDevice.managerData == null ? null : this.stationInfo.childStationDevice.managerData.userName
          this.stationDroneUserDepartmentName = this.stationInfo.childStationDevice == null || this.stationInfo.childStationDevice.managerData == null ? null : this.stationInfo.childStationDevice.managerData.userDepartmentName
          this.stationLiveQuality = this.stationInfo.managerData == null ? null : this.stationInfo.managerData.liveQuality
          if(this.stationLiveQuality == null) {
            this.stationLiveQuality = 'HD_L'
          }

          this.stationDroneLiveQuality = this.stationInfo.childStationDevice == null || this.stationInfo.childStationDevice.managerData == null ? null : this.stationInfo.childStationDevice.managerData.liveQuality
          if(this.stationDroneLiveQuality == null) {
            this.stationDroneLiveQuality = 'HD_L'
          }

          //화면 표출 사용자 정보 저장
          this.showStationUserName = this.stationUserName;
          this.showStationUserDepartmentName = this.stationUserDepartmentName;

          this.showDroneUserName = this.stationDroneUserName;
          this.showDroneUserDepartmentName = this.showStationUserDepartmentName;
        }
      });
    },

    setStationManager() {
      let eventId = this.selectedSite == null ? this.stationInfo.managerData.eventId : this.selectedSite.eventId;
      let stationManagerUserId = this.selectedStationUser == null ? this.stationInfo.managerData.managerUserId : this.selectedStationUser.userId;
      let droneManagerUserId = this.selectedDroneUser == null && this.stationInfo.childStationDevice != null ? this.stationInfo.childStationDevice.managerData.managerUserId : this.selectedDroneUser.userId;

      if(this.selectedSite != null && this.stationInfo.managerData.eventId !== this.selectedSite.eventId) {
        if(this.selectedStationUser == null && this.selectedSite.managerDepartmentId !== this.stationInfo.managerData.eventManagerDepartmentId) {
          alert("현장이 관리 부서가 변경이 되었습니다. 스테이션 관리자를 다시 선택해주세요.")
          return;
        }

        if(this.selectedDroneUser == null &&  this.stationInfo.childStationDevice != null && this.selectedSite.managerDepartmentId !== this.stationInfo.childStationDevice.managerData.eventManagerDepartmentId) {
          alert("현장이 관리 부서가 변경이 되었습니다. 드론 관리자를 다시 선택해주세요.")
          return;
        }
      }

      let params = {
        stationLiveQuality : this.stationLiveQuality,
        stationDroneLiveQuality : this.stationDroneLiveQuality,
        childStationDeviceId : this.stationInfo.childStationDevice == null ? null :  this.stationInfo.childStationDevice.stationDeviceId,
        tag : this.stationTag,
        stationName : this.stationName,
        droneName: this.stationDroneName,
        eventId : eventId,
        stationManagerUserId : stationManagerUserId,
        droneManagerUserId : droneManagerUserId,
      }

      if(params.stationName == null || params.stationName.trim() === "") {
        alert("스테이션 이름을 입력해주세요.")
        return;
      }
      if(params.droneName == null || params.droneName.trim() === "") {
        alert("드론 이름을 입력해주세요.")
        return;
      }

      if(params.eventId == null) {
        alert("현장을 선택 해주세요.")
        return;
      }

      if(params.stationManagerUserId == null) {
        alert("스테이션 관리자를 선택해주세요.")
        return;
      }

      if(params.droneManagerUserId == null) {
        alert("드론 관리자를 선택해주세요.")
        return;
      }

      if(params.stationManagerUserId === params.droneManagerUserId) {
        alert("스테이션 관리자와 드론 관리자는 같을 수 없습니다.")
        return;
      }

      updateStationDeviceManagerInfo(this.stationInfo.stationDeviceId, params).then((res) => {
        if (res.status == 200 && res.data.result == 0) {
          alert("등록이 완료되었습니다.")
          this.$router.go(-1)
        }
      }).catch((error) => {
        if(error.response.data.result === -22001 || error.response.data.result === -22002 || error.response.data.result === -23001) {
          alert(error.response.data.message)
        } else {
          alert("관리자 문의 하세요")
          console.error(error.response)
        }
      });
      params;

      console.log(params)
    },
    //endregion
  },
};
</script>
